import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Gallery = makeShortcode("Gallery");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/55a2a105143f1262413105a52a7701a1/e2731/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "143.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAdABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAQADBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIDBP/aAAwDAQACEAMQAAAB6xNODeIWyEcdqhX/xAAWEAADAAAAAAAAAAAAAAAAAAACEDH/2gAIAQEAAQUCQRDEMQT/xAAXEQADAQAAAAAAAAAAAAAAAAAAATEQ/9oACAEDAQE/ARTFD//EABYRAQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwFcr//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CT//EABkQAAIDAQAAAAAAAAAAAAAAABCxAAExQf/aAAgBAQABPyG9DJ0OLpehk//aAAwDAQACAAMAAAAQXMXP/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEhEP/aAAgBAwEBPxAeMWD/xAAYEQACAwAAAAAAAAAAAAAAAAAAARAhMf/aAAgBAgEBPxAS40f/xAAbEAACAgMBAAAAAAAAAAAAAAAAASExEHHwEf/aAAgBAQABPxCzZ6xp8pDtB6PLlDt7wsuUWbxaP//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/55a2a105143f1262413105a52a7701a1/e5166/01.jpg",
              "srcSet": ["/static/55a2a105143f1262413105a52a7701a1/f93b5/01.jpg 300w", "/static/55a2a105143f1262413105a52a7701a1/b4294/01.jpg 600w", "/static/55a2a105143f1262413105a52a7701a1/e5166/01.jpg 1200w", "/static/55a2a105143f1262413105a52a7701a1/e2731/01.jpg 1462w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/713c0fd550b62f16edf4a8f28e7c4fb8/e2731/02.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "143.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAdABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAEDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIDBP/aAAwDAQACEAMQAAAB65ZpwboFsoOOwFf/xAAWEAADAAAAAAAAAAAAAAAAAAACEDH/2gAIAQEAAQUCQRBEMQT/xAAXEQADAQAAAAAAAAAAAAAAAAAAATEQ/9oACAEDAQE/ARTFD//EABYRAQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwFcr//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CT//EABoQAAIDAQEAAAAAAAAAAAAAAAAxARCxEXH/2gAIAQEAAT8hl0z0l1oS62JZ00P/2gAMAwEAAgADAAAAEEwGz//EABcRAAMBAAAAAAAAAAAAAAAAAAABIRD/2gAIAQMBAT8QHjFg/8QAFhEBAQEAAAAAAAAAAAAAAAAAADEQ/9oACAECAQE/EFZT/8QAHhAAAgEFAAMAAAAAAAAAAAAAAAEhEBExcfBRgfH/2gAIAQEAAT8QybPZ5XEjJRp8wjJssLKX8DS3RdfPEj//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "02",
              "title": "02",
              "src": "/static/713c0fd550b62f16edf4a8f28e7c4fb8/e5166/02.jpg",
              "srcSet": ["/static/713c0fd550b62f16edf4a8f28e7c4fb8/f93b5/02.jpg 300w", "/static/713c0fd550b62f16edf4a8f28e7c4fb8/b4294/02.jpg 600w", "/static/713c0fd550b62f16edf4a8f28e7c4fb8/e5166/02.jpg 1200w", "/static/713c0fd550b62f16edf4a8f28e7c4fb8/e2731/02.jpg 1462w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/af3718e3479a6b82d01000a4ea658b1b/e2731/03.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "143.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAdABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAEDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIDBP/aAAwDAQACEAMQAAAB6lmnBugWxDjqoV//xAAWEAADAAAAAAAAAAAAAAAAAAACEDH/2gAIAQEAAQUCQRDEMQT/xAAXEQADAQAAAAAAAAAAAAAAAAAAATEQ/9oACAEDAQE/ARTFD//EABYRAQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwFcr//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CT//EABkQAAIDAQAAAAAAAAAAAAAAABAxAHGxAf/aAAgBAQABPyHrDLOxdZ0n/9oADAMBAAIAAwAAABDczv8A/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEhEP/aAAgBAwEBPxAeMWD/xAAWEQEBAQAAAAAAAAAAAAAAAAAAMRD/2gAIAQIBAT8QVlP/xAAdEAACAQUBAQAAAAAAAAAAAAAAAXEQESEx8EFh/9oACAEBAAE/ENk07PiG8uS7HzD25ovpxDeXJc3j/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "03",
              "title": "03",
              "src": "/static/af3718e3479a6b82d01000a4ea658b1b/e5166/03.jpg",
              "srcSet": ["/static/af3718e3479a6b82d01000a4ea658b1b/f93b5/03.jpg 300w", "/static/af3718e3479a6b82d01000a4ea658b1b/b4294/03.jpg 600w", "/static/af3718e3479a6b82d01000a4ea658b1b/e5166/03.jpg 1200w", "/static/af3718e3479a6b82d01000a4ea658b1b/e2731/03.jpg 1462w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/c5f39293bf1ec87a26b6dbd504df26c4/e2731/04.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "143.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAdABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAEDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIDBP/aAAwDAQACEAMQAAAB65Zpw7AVyDlqoU//xAAWEAADAAAAAAAAAAAAAAAAAAACEDH/2gAIAQEAAQUCQRDEMQz/xAAXEQADAQAAAAAAAAAAAAAAAAAAATEQ/9oACAEDAQE/ARTFD//EABYRAQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwFcr//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CT//EABoQAAICAwAAAAAAAAAAAAAAABAxAHEBILH/2gAIAQEAAT8hywyz22nXP//aAAwDAQACAAMAAAAQW8uD/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQITH/2gAIAQMBAT8QMoWh/8QAFhEBAQEAAAAAAAAAAAAAAAAAADEQ/9oACAECAQE/EFZT/8QAHhAAAQMFAQEAAAAAAAAAAAAAAQAxcRARIWHwwfH/2gAIAQEAAT8Qcmm18BG9zKyjsXGCLmaBlPiLmadGgv/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "04",
              "title": "04",
              "src": "/static/c5f39293bf1ec87a26b6dbd504df26c4/e5166/04.jpg",
              "srcSet": ["/static/c5f39293bf1ec87a26b6dbd504df26c4/f93b5/04.jpg 300w", "/static/c5f39293bf1ec87a26b6dbd504df26c4/b4294/04.jpg 600w", "/static/c5f39293bf1ec87a26b6dbd504df26c4/e5166/04.jpg 1200w", "/static/c5f39293bf1ec87a26b6dbd504df26c4/e2731/04.jpg 1462w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      